<template>
  <v-dialog v-model="editDialog" width="500">
    <template v-slot:activator="{ on, attrs }">
      <v-icon v-on="on" v-bind="attrs" color="info">mdi-pencil</v-icon>
    </template>
    <v-card>
      <v-card-title class="d-flex justify-center card-title__heading">
        <h2>{{ $t('edit') }} {{ recordData.name }}</h2>
      </v-card-title>
      <v-card-text>
        <v-text-field :label="$t('name')" v-model="editFloor.name" outlined dense color="#757575"
          type="text"></v-text-field>
        <v-text-field :label="$t('arrangement')" v-model="editFloor.order" outlined dense color="#757575"
          type="number"></v-text-field>
      </v-card-text>
      <v-card-actions class="justify-center">
        <v-btn color="green" class="white--text" depressed @click="editRecord" :loading="editBtnLoading"
          :disabled="disableEditBtn">{{ $t('edit') }}</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import axios from "axios";
export default {
  data() {
    return {
      editFloor: {
        name: "",
        order: null,
      },
      editBtnLoading: false,
      editDialog: false,
    };
  },
  props: {
    recordData: Object,
  },
  watch: {
    editDialog() {
      this.editFloor.name = this.recordData.name;
      this.editFloor.order = this.recordData.order;
    },
  },
  computed: {
    disableEditBtn() {
      if (!this.editFloor.name || !this.editFloor.order) {
        return true;
      } else {
        return false;
      }
    },
  },
  methods: {
    async editRecord() {
      try {
        this.editBtnLoading = true;
        await axios.put(`/floor/${this.recordData.id}`, {
          name: this.editFloor.name,
          order: this.editFloor.order,
        });
        this.$Notifications(
          this.$t('edit success'),
          { timeout: 2000, rtl: true },
          "success"
        );
        this.$emit("recordUpdated");
        this.editDialog = false;
      } catch (err) {
      } finally {
        this.editBtnLoading = false;
      }
    },
  },
  created() {
    this.editFloor.name = this.recordData.name;
    this.editFloor.order = this.recordData.order;
  },
};
</script>

<style>

</style>